<template lang="pug">
  a.link(v-bind="componentProps")
    slot
      span(
        v-if="title"
        v-html="title"
      )
</template>

<script lang="ts">
  import { isAmpPage, isTheRecipeAmpPage } from '~/utils/router';
  import { getRelativeLink } from '~/utils';

  export default defineNuxtComponent({
    name: 'TheLink',

    props: {
      link: {
        type: String,
        required: true,
      },

      title: {
        type: String,
        required: false,
        default: '',
      },

      backgroundColor: {
        type: String,
        default: '',
      },

      isActive: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      route: () => useRoute(),
      componentProps(): {
        readonly href: string;
        readonly title: string | undefined;
        readonly style: Record<string, string> | null;
        readonly 'data-vars-link-href'?: string;
      } {
        const isAmp = isAmpPage(this.route?.name) || isTheRecipeAmpPage(this.route?.name);
        const href = getRelativeLink(this.link ?? '');

        return {
          href,
          title: this.title ? this.title : undefined,
          style: this.isActive ? { 'background-color': this.backgroundColor } : null,
          'data-vars-link-href': isAmp ? href : undefined,
        };
      },
    },
  });
</script>
